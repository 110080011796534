@import "../hsg";

// ==================================================================
// typography

h1, h2, h3, h4, h5, h6 {
	font-family: "Futura Bold", Roboto, sans-serif;
	color: #454545;
}

p, a, li, span, div {
	font-family: "Futura Medium", Roboto, sans-serif;
}


.breaking-news__attention {
	color: #454545;
}

.breaking-news {
	.share__button__icon {
		color: white;
	}	
}

.news-teaser {
	.news-teaser__item {
		.news-teaser__title {
			font-family: "Futura Medium", Roboto, sans-serif;
			color: #454545;
		}
	}
}

.current-program__icons {
	a {
		background-color: $secondary-color !important;	
	}
	i {
		color: white !important;
	}
}

.current-program__title {
	color: #454545;
}

.current-program__subtitle {
	color: #454545;

	a {
		color: #454545;
	}
}

.breaker__topic {
	color: #454545;
}

.webradio-play i {
	color: white;
}

.webradio-title {
	color: white;
}

// ==================================================================
// header

.nav-wrapper {
	border-top: 3px solid white;
}

.sidenav-trigger i {
	color: #454545;
}

.sidenav-page {
	background: $secondary-color;

	.sidenav-close {
		font-family: "Futura Medium", Roboto, sans-serif;
		font-weight: bold;
		background: none;
		font-size: 3rem;

		i {
			display: none;
		}
	}

	.sidenav__navigation > li > a {
		font-size: 2.2rem !important;
	}
	
	.sidenav__navigation > li {
		li a {
			font-size: 1.6rem !important;
		}
	}

	ul, li, a {

		font-family: "Futura Medium", Roboto, sans-serif;
		font-weight: lighter !important;
		color: white !important;
	}
}

.nav-mobile-header img {
	margin-bottom: 7px;
	margin-top: 7px;
	height: 52px;
	width: auto;
}


.nav-icon-item * {
		color: #454545 !important;
}

.nav-background {
	background-color: $primary-color;
}

.webradio--header {
    flex-direction: row-reverse;

    .webradio-play {
		overflow: hidden;
        background-color: $secondary-color;
        border-radius: 0%;
		padding: rem(4px);
	}

    .webradio-title {
		background: black;
		padding: rem(4px);
	}
	
	.webradio-cover {
		margin-left: rem(18px);
	}
}

.nav-social-media-links {
	display: none;
	padding-left: rem(14px);

	@include media-breakpoint-up(xl) {
		display: flex;
	}

	a {
		margin-left: 1.125rem;
		position: relative;
		overflow: hidden;
		display: block;
		height: 48px;
		width: 48px;

		img {
			position: absolute;
			height: auto;
			width: 100%;
			left: 0;
			top: 0;
		}
	}
}

// ==================================================================
// floating action buttons 

.floating-ivw-logo {
	display: none;
}

.floating-share-button {
	.fixed-action-btn {
		bottom: 228px;
		width: 56px;
		left: 23px;
	}
}

.floating-scroll-button {
	bottom: 153px;
	width: 56px;
	left: 23px;
}

.btn-floating {
	i {
		color: color("grey", "darken-3");
	}
}

// ONLY FOR CLICK DUMMY !!!!
// TODO: Remove after click dummy 
.nav-social-media-links {
	@include media-breakpoint-up(xl) {
		display: flex !important;
	}
}

.nav-social-media-links-koeln {
	display: none !important;
}

nav.breadcrumb {
	a {
		&:hover {
			color: $secondary-color;
		}
	}
}

.loader {
	.btn & {
		border: .15rem solid rgba(color("grey", "darken-3"), 0.2);
		border-left: .15rem solid color("grey", "darken-3");
	}
}

.btn--icon__image path {
	fill: color("grey", "darken-3");
	stroke: color("grey", "darken-3");
}